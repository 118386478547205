.main {
  display: flex;
  flex-direction: column;
}
.header {
  display: flex;
  justify-content: center;
  padding: 27.5px 0;
  background-color: #f8f8f8;
}
.header > ul {
  display: flex;
  align-items: center;
}
.header > ul > li {
  margin: 7.5px 190px;
  cursor: pointer;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.55px;
  text-align: left;
}
.header hr {
  align-self: normal;
  background-color: #b5b5b5;
  border: none;
  width: 2px;
}
.article {
  width: var(--main-width);
  margin: 0 auto;
  padding: 102px 0 200px;
  display: flex;
  gap: 21px;
}
.section {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.select_filter {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 22px 0;
}
.select_filter > li {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 2px 16px;
  border-radius: 13px;
  background-color: #009398;
  border: none;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.4px;
  text-align: left;
  color: #fff;
}
.select_filter > li:last-child {
  background-color: #051d42;
}
.select_filter > li > svg:hover {
  cursor: pointer;
}
.voice_list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
