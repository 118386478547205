.main {
  width: var(--main-width);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.main h1 {
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.75px;
}
.result_word {
  align-self: center;
  margin: 80px 0 13px;
}
.result_count {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.4px;
  color: #000;
  align-self: flex-end;
  margin-bottom: 20px;
}
.result_count > span {
  color: var(--red-color);
}
.result_category {
  display: flex;
  background-color: #f8f8f8;
  padding: 13px 0;
  border-top: 3px solid var(--main-color);
}
.result_category > li {
  flex: 1;
  text-align: center;
  padding: 7px 0;
}
.result_category > hr {
  width: 1px;
  border: none;
  background-color: #b5b5b5;
}
.result_category > li > span {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.55px;
  text-align: center;
  color: #b5b5b5;
}
.result_category > li > span:hover {
  cursor: pointer;
  color: #009398 !important;
}
.result_none {
  margin: 100px 350px;
  text-align: center;
}
.actor_list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-top: 32px;
}
.actor_list > li {
  flex-basis: calc((100% / 3) - 20px);
}
.voice_list {
  display: flex;
  flex-direction: column;
  gap: 21px;
  margin-top: 32px;
}
