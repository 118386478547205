.main_wrap {
  width: var(--main-width);
  margin: 0 auto;
  padding: 100px 0 200px;
  display: flex;
  flex-direction: column;
}
.main_wrap > hr {
  width: 50px;
  height: 5px;
  border-radius: 2.5px;
  background-color: var(--main-color);
  border: none;
  margin-bottom: 10px;
}
.main_wrap > h2 {
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.75px;
  text-align: left;
  color: #000;
}
.main_wrap > ul {
  margin: 63.5px 0 81.5px;
  border-top: 1px solid #ddd;
}
.main_wrap > ul > li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.55px;
  color: #000;
  padding: 20px 44px;
}
.title:hover {
  cursor: pointer;
  text-decoration: underline;
  text-underline-position: under;
}
.date {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.4px;
  color: #999;
}
