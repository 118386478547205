.voiceplay_button {
  cursor: pointer;
  display: block;
}
.li_wrap {
  border-radius: 4px;
  border: solid 1px #ddd;
}
.li_wrap_topdiv {
  padding: 29.5px 88px;
  background-color: var(--white-color);
  display: flex;
  align-items: center;
  gap: 20px;
}
.li_wrap_topdiv > p {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.45px;
  text-align: left;
  color: #000;
}
.genre_ul {
  display: flex;
  gap: 8px;
  padding: 20px 88px;
  background-color: #f4f5f5;
}
.genre_ul > li {
  padding: 1px 16px;
  border-radius: 13px;
  background-color: #009398;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.4px;
  text-align: left;
  color: var(--white-color);
}
.topdiv_title {
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.55px;
  color: #000;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.topdiv_user {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.5px;
  color: #009398;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  align-self: flex-start;
  max-width: 100%;
}
.li_wrap_topdiv > div:nth-child(1) {
  width: 246px;
  display: flex;
  flex-direction: column;
}
.li_wrap_topdiv > p {
  flex-shrink: 0;
}
.progress_wrap {
  flex: 1;
  display: flex;
}
.progress_wrap > li {
  height: 55px;
  box-sizing: border-box;
  /* cursor: pointer; */
  animation-name: scaleYUpDown;
  animation-duration: 0.5s;
  animation-direction: alternate;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.progress_show {
  width: 6px;
  border-radius: 3px;
}
.progress_hidden {
  width: 4px;
}
@keyframes scaleYUpDown {
  from {
    transform: scaleY(0.4);
  }
  to {
    transform: scaleY(1);
  }
}
