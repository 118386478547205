.pagination_wrap {
  display: flex;
  gap: 24px;
  align-items: center;
  align-self: center;
}
.pagination_wrap > ul {
  display: flex;
  gap: 6px;
  align-items: center;
}
.pagination_wrap > ul > li {
  padding: 2px 9px;
  border-radius: 2px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.4px;
  text-align: center;
  color: #999;
}
.pagination_wrap > ul > li:hover {
  cursor: pointer;
  background-color: var(--main-color);
  color: var(--white-color);
}
.pagination_wrap_selected {
  background-color: var(--main-color);
  color: var(--white-color) !important;
}
.pagination_wrap > button {
  border: none;
  background-color: transparent;
}
.pagination_wrap > button > svg {
  display: block;
}
.pagination_wrap > button > svg:hover {
  color: var(--main-color) !important;
}
