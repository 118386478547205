footer {
  margin-top: auto;
  display: flex;
  justify-content: center;
  background-color: #1c1c1c;
}
.footer_wrap {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: var(--main-width);
  padding: 50px 0;
}
.footer_wrap a {
  color: #9f9f9f;
}
.footer_wrap_bold_list {
  display: flex;
  gap: 65px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.55px;
  color: var(--white-color);
}
.footer_wrap_bold_list > li:hover {
  cursor: pointer;
}
.footer_wrap_normal_list {
  display: flex;
  gap: 60px;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: #9f9f9f;
}
.footer_wrap_copyright {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  color: #9f9f9f;
}
