@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR&display=swap");
#root {
  font-family: "Noto Sans KR", sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-size: 16px;
}
:root {
  --main-width: 1276px;
  --main-color: #76c2c9;
  --black-color: #000;
  --white-color: #fff;
  --red-color: #ff2c00;
  --text-gray-color: #999;
  --border-gray-color: #ddd;
}
