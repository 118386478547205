.filter {
  padding: 22px 10px 36px;
  min-width: 196px;
  max-width: 196px;
  border: solid 1px #ddd;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}
.filter h2 {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.55px;
  text-align: left;
  color: #000;
}
.filter hr {
  background-color: #ddd;
  border: none;
  height: 1px;
  margin-top: 22px;
}
.filter_title_div {
  margin: 0 7px;
}
.filter_title_div,
.filter_section_title_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filter_title_div > p {
  cursor: pointer;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.4px;
  text-align: left;
  color: #000;
}
.filter_section {
  margin-top: 20px;
  margin: 0 7px;
}
.filter_section_title_div {
  margin: 20px 0 10px;
}
.filter_section_title_div > svg {
  cursor: pointer;
}
.filter_section > ul {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.filter_section > ul > li {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.55px;
  text-align: left;
  color: #000;
}
.filter_section > ul > li > span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.checkbox_wrap {
  flex-shrink: 0;
  border-radius: 4px;
  border: solid 1px #ddd;
  width: 23px;
  height: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
