.main_wrap {
  width: var(--main-width);
  margin: 0 auto;
  padding: 100px 0 200px;
  display: flex;
  flex-direction: column;
}
.main_wrap hr {
  display: block;
  width: 50px;
  height: 5px;
  border: none;
  border-radius: 2.5px;
  background-color: var(--main-color);
  margin-bottom: 10px;
}
.main_wrap h1 {
  font-size: 40px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -1px;
  color: #000;
}
.main_wrap h2 {
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.8px;
  text-align: left;
  color: #000;
  margin: 10px 0 10px;
}
.main_wrap p {
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.55px;
  line-height: 1.64;
  color: #666;
}
.main_wrap span {
  line-height: 2.4;
  margin-left: 10px;
}
.main_wrap nav {
  display: flex;
  gap: 220px;
  margin: 30px 0 80px;
}
.main_wrap nav > ul {
  padding: 10px 20px;
  border-left: 2px solid #ddd;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.main_wrap nav > ul > li {
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.55px;
  color: #000;
}
.main_wrap nav > ul > li > a:hover {
  cursor: pointer;
  font-weight: bold;
}
.main_wrap table {
  border-collapse: collapse;
}
.main_wrap table td {
  border: 1px solid #707070;
  padding: 16px 20px 15px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.55px;
  text-align: left;
  color: #666;
}
.main_wrap table > thead {
  background-color: #f9f9f9;
}
.main_wrap table > thead td {
  font-weight: bold !important;
  color: #222;
}
