.main {
  width: var(--main-width);
  margin: 0 auto;
  padding: 100px 0 127.5px;
  display: flex;
  flex-direction: column;
}
.main h2 {
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.55px;
  text-align: left;
  color: #000;
}
.main hr {
  width: 103px;
  height: 3px;
  margin: 8px 0 8px;
  background-color: #000;
  border: none;
}
.main_caution {
  padding: 20px 30px;
  border: solid 1px #ddd;
  background-color: #f9f9f9;
  display: flex;
  gap: 10px;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.5px;
  text-align: left;
  color: #666;
}
.main_caution span {
  color: var(--red-color);
}
.table {
  border-collapse: collapse;
  margin: 35px 0 80px;
}
.table td {
  border: 1px solid #ddd;
  border-left: none;
  border-right: none;
}
.table_title {
  background-color: #f9f9f9;
  padding: 20px;
  width: 162px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.55px;
  text-align: left;
  color: #000;
}
.required::after {
  content: "*";
  color: var(--red-color);
  margin-left: 2px;
}
.table_selectbox {
  width: 291px;
  padding: 10px;
  border-radius: 3px;
  border: solid 1px #ddd;
  background-color: var(--white-color);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.4px;
  color: #999;
}
.table_selectbox > option:disabled {
  color: #999;
}
.table_selectbox > option {
  color: #000;
}
.table_content {
  padding: 13px 19.6px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.4px;
  color: #999;
}
.customform_wrap {
  display: flex;
  flex-direction: column;
}
.customform_wrap > button[type="submit"] {
  align-self: center;
  padding: 14px 78px;
  border: none;
  border-radius: 4px;
  background-color: var(--main-color);
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.55px;
  color: var(--white-color);
}
.table_input {
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  border: solid 1px #ddd;
  background-color: var(--white-color);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.4px;
  text-align: left;
  /* color: #999; */
}
.table_input::placeholder {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.4px;
  text-align: left;
  color: #999;
}
.table_textarea {
  resize: vertical;
}
.caution {
  align-self: flex-end;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.4px;
  margin: 0 19.6px 25px 0;
}
.caution > span {
  color: var(--red-color);
}
.spinner {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.spinner > div {
  width: 60px;
  height: 60px;
  border-width: 5px;
  border-color: #777;
  border-top-color: var(--main-color);
  border-style: solid;
  border-radius: 50%;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
