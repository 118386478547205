.nav {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 9999;
}
.nav > ul {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.nav > ul > li > a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 50%;
  border: solid 1px var(--main-color);
  background-color: var(--white-color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.nav_top_button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 50%;
  border: solid 1px var(--main-color);
  background-color: var(--white-color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: var(--main-color) !important;
}
