.header {
  background-color: var(--main-color);
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  /* position: sticky;
  top: 0;
  z-index: 9999; */
}
.header_wrap {
  width: var(--main-width);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}
.header_wrap > ul {
  display: flex;
  align-items: center;
  gap: 55px;
}
.header_wrap > ul > li:hover {
  cursor: pointer;
}
.header_wrap > ul > li > span {
  color: var(--white-color);
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.65px;
  box-sizing: border-box;
}
.header_nav {
  padding: 27px 0 22px;
  border-bottom: 5px solid transparent;
}
.header_nav:hover {
  border-bottom: 5px solid var(--white-color) !important;
}
.header_wrap_search_wrap {
  display: flex;
  gap: 20px;
}
.header_wrap_search_wrap > input[type="text"] {
  width: 440px;
  padding: 10.5px 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 147, 152, 0.4);
  background-color: #f1feff;
  border-radius: 5px;
  border: none;
  outline: none;
}
.header_wrap_search_wrap > input[type="text"]::placeholder {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.4px;
  color: #b5b5b5;
}
.search_wrap_search_button {
  padding: 10px 17px 10px 18px;
  border-radius: 4px;
  background-color: var(--white-color);
  border: none;
}
/* .nav {
  position: absolute;
  left: 0;
  right: 0;
  display: none;
  justify-content: center;
  padding: 27.5px 0;
  background-color: #f8f8f8;
}
.nav > ul {
  display: flex;
  align-items: center;
}
.nav > ul > li {
  margin: 7.5px 190px;
  cursor: pointer;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.55px;
  text-align: left;
}
.nav hr {
  align-self: normal;
  background-color: #b5b5b5;
  border: none;
  width: 2px;
} */
.nav {
  position: absolute;
  top: 100%;
  background-color: #f8f8f8;
}
.nav > ul > li {
  padding: 5px 25px;
  font-weight: bold;
}
.nav > ul > li:hover {
  cursor: pointer;
  color: #009398 !important;
}
