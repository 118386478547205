.main_wrap {
  width: var(--main-width);
  margin: 0 auto;
  padding: 100px 0 200px;
  display: flex;
  flex-direction: column;
}
.main_wrap p {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.5px;
  text-align: left;
  color: #000;
}
.back_nav {
  display: flex;
  align-items: center;
  gap: 18px;
}
.back_nav > svg:hover {
  cursor: pointer;
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.55px;
  color: #000;
  padding: 20px 44px;
  margin: 30px 0;
}
.date {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.4px;
  color: #999;
}
.content {
  padding: 40px 47px;
  background-color: #f9f9f9;
}
.content img {
  display: block;
  margin: 25px 0;
  max-width: 100%;
  height: auto;
}
