.main {
  display: flex;
  flex-direction: column;
}
.article {
  width: var(--main-width);
  margin: 0 auto;
  padding: 102px 0 200px;
  display: flex;
  gap: 21px;
}
.section {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.select_filter {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 22px 0;
}
.select_filter > li {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 2px 16px;
  border-radius: 13px;
  background-color: #009398;
  border: none;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.4px;
  text-align: left;
  color: #fff;
}
.select_filter > li:last-child {
  background-color: #051d42;
}
.select_filter > li > svg:hover {
  cursor: pointer;
}
.actor_list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.actor_list > li {
  flex-basis: calc((100% / 3) - 16px);
}
