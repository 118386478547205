* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
ul,
ol {
  list-style: none;
}
button:hover {
  cursor: pointer;
}
a {
  text-decoration: none;
  color: #000;
}
