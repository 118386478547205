.popup {
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 10%;
  top: 10%;
  width: 30%;
  background-color: #ffffff;
  border: 1px solid #b5b5b5;
  z-index: 9999;
}
