.main_header {
  display: flex;
  justify-content: center;
  background-color: #fbfbfb;
}
.main_header_wrap {
  display: flex;
  align-items: center;
  position: relative;
  width: var(--main-width);
  padding: 88px 0 100px;
}
.main_header_wrap_main_logo {
  position: absolute;
  bottom: 0;
  right: 0;
}
.main_header_wrap_main_text_wrap {
  display: flex;
  flex-direction: column;
  gap: 60px;
  z-index: 1;
}
.main_text_wrap_main_text {
  font-size: 50px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -1.25px;
  text-align: left;
  color: #000;
}
.main_text_wrap_button {
  align-self: flex-start;
  border-radius: 4px;
  border: solid 1px var(--main-color);
  background-color: var(--white-color);
  padding: 14px 25.5px;
  font-size: 22px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.55px;
  text-align: left;
  color: var(--main-color);
}
.main_article {
  width: var(--main-width);
  margin: 0 auto;
  background-color: var(--white-color);
  padding: 80px 0 192px;
  display: flex;
  flex-direction: column;
  gap: 121px;
}
.main_article_section > hr {
  display: inline-block;
  width: 50px;
  height: 5px;
  border-radius: 2.5px;
  border: none;
  background-color: var(--main-color);
}
.main_article_section > h2 {
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.75px;
  color: #000;
}
.voiceactor_ul {
  display: flex;
  border-radius: 4px;
  gap: 13px;
  padding: 30px 20px;
  background-color: #f9f9f9;
}
.voiceactor_ul > li {
  flex-basis: calc(25% - 13px);
  flex-grow: 0 !important;
  padding: 20px;
  border-radius: 4px;
  border: solid 1px #ddd;
  background-color: var(--white-color);
  display: flex;
  flex-direction: column;
}
.voiceactor_ul > li > img {
  width: 100%;
  height: auto;
}
.section_title_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}
.section_title_wrap_showmore {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.55px;
  text-align: left;
  color: #000;
}
.voicesample_ul {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.swiper_slide {
  height: auto !important;
}
.swiper_wrap {
  border-radius: 4px;
  padding: 30px 20px;
  background-color: #f9f9f9;
}
