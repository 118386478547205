.main {
  width: var(--main-width);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 80px 0 200px;
}
.main h2 {
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.65px;
  color: #000;
}
.main p {
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.55px;
  text-align: left;
  color: #000;
}
.main hr {
  width: 50px;
  height: 5px;
  border-radius: 2.5px;
  background-color: var(--main-color);
  border: none;
  margin-bottom: 10px;
}
.back_nav {
  display: flex;
  align-items: center;
  gap: 18px;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.5px;
  text-align: left;
  color: #000;
}
.back_nav > svg:hover {
  cursor: pointer;
}
.actorinfo_wrap {
  margin-top: 40px;
  display: flex;
  gap: 70px;
}
.actorinfo_wrap > div {
  align-self: flex-start;
}
/* 성우정보 왼쪽 */
.actordata_wrap {
  flex-basis: 520px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px;
  border: 1px solid #ddd;
  border-top: 5px solid var(--main-color);
  border-radius: 4px;
}
.actordata_wrap img {
  object-fit: contain;
  border-radius: 50%;
  border: 1px solid #ddd;
}
.actordata_wrap h2 {
  display: flex;
  gap: 8px;
  margin: 35px 0 22px;
}
/* 성우정보 오른쪽 */
.voicedata_wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.voicedata_wrap h2 {
  font-weight: bold;
  margin-bottom: 20px;
}
.piecelist_ul {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 50px;
}
.piecelist_ul > li {
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.55px;
  color: #000;
}
.taglist_ul {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
.taglist_ul > li {
  padding: 1px 16px;
  border-radius: 13px;
  background-color: #00969b;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.4px;
  color: var(--white-color);
}
.voice_list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
}
