.voicelist_wrap {
  padding: 20px;
  border-radius: 4px;
  border: solid 1px #ddd;
  background-color: var(--white-color);
  display: flex;
  flex-direction: column;
  height: 100%;
  cursor: pointer;
  position: relative;
}
.voicelist_wrap > img {
  width: 100%;
  object-fit: contain;
}
.voiceactor_ul_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0 8px;
}
.voiceactor_ul_header > div {
  display: flex;
  gap: 18px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -1.58px;
  text-align: left;
  color: #000;
  flex-shrink: 0;
}
.actorname {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* 위의 3옵션을 해도 이상해서 max-width로 강제 */
  max-width: 200px;
}
.musicsource_count {
  color: var(--main-color);
}
.voiceactor_ul_introduction {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.4px;
  color: #666;
  max-height: 84px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.taglist_ul {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
.taglist_ul > li {
  padding: 1px 16px;
  border-radius: 13px;
  background-color: #00969b;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.4px;
  color: var(--white-color);
}
.association_marker {
  position: absolute;
  top: 0;
  right: 0;
  padding: 14px 16px;
  color: var(--white-color);
  background-color: var(--main-color);
  font-weight: bold;
  letter-spacing: -0.4px;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
}
